import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import PhotoLeft from "../../components/photo-left";
import TreatmentHero from "../../components/treatment-hero";
import ReviewSection from "../../components/review-section";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import uuid4 from "uuid4";

const VeneersAndBondingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "veneers-and-bonding" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        veneersAndBondingFieldGroups {
          veneersAndBondingIntroSection {
            heading
            subHeading
            title
            subtitle
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          veneersAndBondingReviewSection {
            heading
            subHeading
          }
          whatYouKnowSection {
            items {
              content
              heading
            }
            heading
            subHeading
            cta {
              title
              target
              url
            }
          }
          veneersAndBondingTwoColumnSection {
            heading
            content
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          veneersAndBondingFaqSection {
            heading
            faqs {
              nodes {
                ... on WpFaq {
                  faqFields {
                    question
                    answer
                  }
                }
              }
            }
          }
          pricingSection {
            heading
            content
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, veneersAndBondingFieldGroups, slug },
  } = data;

  const {
    veneersAndBondingIntroSection,
    veneersAndBondingReviewSection,
    whatYouKnowSection,
    veneersAndBondingTwoColumnSection,
    veneersAndBondingFaqSection,
    pricingSection,
  } = veneersAndBondingFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
    name: "Dental House Pinner",
    url: "https://www.dentalhousepinner.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
      image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Pinner",
      url: "https://www.dentalhousepinner.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhousepinner.co.uk",
      datePublished: "2023-08-23",
      reviewBody:
        "As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
      author: {
        "@type": "Person",
        name: "N R",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Pinner",
        sameAs: "https://www.dentalhousepinner.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Veneers and Bonding",
        item: {
          url: `${siteUrl}/treatments/veneers-and-bonding`,
          id: `${siteUrl}/treatments/veneers-and-bonding`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/veneers-and-bonding`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {veneersAndBondingIntroSection &&
          !checkPropertiesForNull(veneersAndBondingIntroSection, [
            "heading",
          ]) && (
            <TreatmentHero
              heading1={veneersAndBondingIntroSection?.title}
              smallHeadingTopText={veneersAndBondingIntroSection?.subtitle}
              img={
                veneersAndBondingIntroSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={veneersAndBondingIntroSection?.image.node?.altText}
              heading2={veneersAndBondingIntroSection?.subHeading}
              headingSmall={veneersAndBondingIntroSection?.heading}
              text={
                <SafeHtmlParser
                  htmlContent={veneersAndBondingIntroSection?.description}
                />
              }
              btnLink={veneersAndBondingIntroSection?.cta.url}
              btnText={veneersAndBondingIntroSection?.cta?.title}
            />
          )}

        <ReviewSection
          heading={
            veneersAndBondingReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            veneersAndBondingReviewSection?.subHeading ||
            "WHAT PEOPLE SAY ABOUT US"
          }
          page="Veneers and bonding"
        />
        {whatYouKnowSection &&
          !checkPropertiesForNull(whatYouKnowSection, ["heading"]) && (
            <section className="pt-8 pb-md-6 pb-5">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {whatYouKnowSection?.subHeading}
                    </p>
                    <h2 className="text-text-secondary display-4 pb-4">
                      {whatYouKnowSection?.heading}
                    </h2>
                  </Col>
                </Row>
                {whatYouKnowSection?.items &&
                  whatYouKnowSection?.items.length > 0 && (
                    <Row className="pt-md-8 g-3 pt-5 h-100 justify-content-center">
                      {" "}
                      {whatYouKnowSection?.items.map((item) => (
                        <Col style={{ minHeight: "100%" }} lg={4} key={uuid4()}>
                          <div
                            style={{
                              boxShadow: "0px 3px 50px #00000029",
                            }}
                            className="bg-white h-100 px-4 py-5 feature-box"
                          >
                            <h3 className="fs-3 text-center pb-3 ">
                              {item?.heading}
                            </h3>
                            <SafeHtmlParser htmlContent={item?.content} />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}

                {whatYouKnowSection.cta && whatYouKnowSection.cta.url && (
                  <Row>
                    <Col className="text-center mt-5 mt-md-6">
                      {" "}
                      <Button
                        className="text-white py-3 px-5 w-100 w-md-auto"
                        variant="accent"
                        href={whatYouKnowSection.cta?.url}
                        target={whatYouKnowSection.cta?.target ?? "_blank"}
                        rel="noreferrer"
                      >
                        {whatYouKnowSection.cta?.title}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
            </section>
          )}
        {veneersAndBondingTwoColumnSection &&
          !checkPropertiesForNull(veneersAndBondingTwoColumnSection, [
            "heading",
          ]) && (
            <section className=" bg-bg">
              <Container fluid className="px-0 mx-0">
                <Row className=" g-0">
                  <Col lg={{ span: 5, order: "last" }}>
                    <GatsbyImage
                      className="w-100 h-100"
                      image={
                        veneersAndBondingTwoColumnSection.image.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={
                        veneersAndBondingTwoColumnSection.image.node?.altText
                      }
                    />
                  </Col>{" "}
                  <Col
                    className="py-5 px-3 px-lg-6 px-xl-8 py-lg-7 py-xxl-10"
                    lg={7}
                  >
                    <h2 className="  fs-1 mb-5 ">
                      {veneersAndBondingTwoColumnSection?.heading}
                    </h2>
                    <SafeHtmlParser
                      htmlContent={veneersAndBondingTwoColumnSection?.content}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {veneersAndBondingFaqSection &&
          !checkPropertiesForNull(veneersAndBondingFaqSection, ["faqs"]) && (
            <Faq
              heading={veneersAndBondingFaqSection?.heading}
              faqs={veneersAndBondingFaqSection?.faqs.nodes}
            />
          )}
        {pricingSection &&
          !checkPropertiesForNull(pricingSection, ["heading"]) && (
            <section>
              {" "}
              <PhotoLeft
                imgHeight="40rem"
                img={
                  pricingSection?.image.node?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imgAlt={pricingSection?.image.node?.altText}
                text={
                  <div className="text-white text-center">
                    <h2 className="display-4 pb-4">
                      {pricingSection?.heading}
                    </h2>
                    <GatsbyImage
                      className="mb-4 w-100 d-lg-none"
                      image={
                        pricingSection?.image.node?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={pricingSection?.image.node?.altText}
                    />
                    <SafeHtmlParser htmlContent={pricingSection?.content} />
                    {pricingSection && pricingSection.cta && (
                      <div className="text-center">
                        <Button
                          className="text-white mt-lg-4 py-3 px-5 w-100 w-md-auto"
                          variant="accent"
                          href={pricingSection?.cta.url}
                          target={pricingSection.cta.target ?? "_blank"}
                          rel="noreferrer"
                        >
                          {pricingSection?.cta.title}
                        </Button>
                      </div>
                    )}
                  </div>
                }
                bgColour="bg-primary"
              />
            </section>
          )}
      </Layout>
    </>
  );
};

export default VeneersAndBondingPage;
